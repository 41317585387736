<template>
  <div>
    <el-row :gutter="10" style="min-height: 60px;" class="sctp-mar-t10"
            v-loading="partTimeConfig.isLoadData">
      <template v-if="partTimeConfig.data && partTimeConfig.data.length > 0">
        <template v-if="partTimeConfig.data && partTimeConfig.data.length > 0">
          <template v-for="job in partTimeConfig.data">
            <a target="_blank" :href="`/parttime/view/${job.jobid}`" :key="job.jobid">
              <el-col :span="12" class="sctp-mar-b10">
                <div class="sctp-pad-lr15 sctp-pad-tb10 sctp-bg-white site-tag">
                  <div class="sctp-flex pr">
                    <el-image
                      :src="job.imgurl"
                      style="height: 80px;width: 80px;border-radius: 100%;overflow: hidden;margin-right: 15px;"
                      fit="cover"
                      :lazy="true"
                    ></el-image>
                    <div class="sctp-flex-item-1 sctp-flex sctp-flex-column sctp-flex-sb">
                      <div>
                        <span class="sctp-pad-r20">{{ job.account }}</span>
                        <span v-if="city(job.city)"><i class="el-icon-location-outline"></i>{{
                            city(job.city)
                          }}</span>
                      </div>
                      <div class="fz-12 fc-info">
                        <span>{{ job.president }}</span>
                      </div>
                      <div>
                    <span class="sctp-color-main fz-12">¥{{ job.expectedSalary }}/{{
                        job.salaryUnit.replace('按', '')
                      }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="divider"></div>
                  <div class="sctp-font-12 sctp-lh-medium">
                    <div class="sctp-pad-tb5">
                      最擅长技能：
                      <el-tag class="sctp-mar-r5" type="success"
                              size="mini"
                              effect="plain"
                              v-for="skill in job.jobUserSkillIdList"
                              :key="skill.id">
                        {{ skill.skillname }}
                      </el-tag>
                    </div>
                    <div class="sctp-pad-tb5 rich-text sctp-ellipsis">
                      简介：<span class="rich-text" v-html="job.aboutMyself"></span>
                    </div>
                  </div>
                  <div class="sctp-flex sctp-flex-sb">
                    <div></div>
                    <div>
                      <el-tag
                        size="mini"
                        type="warning"
                        effect="dark"
                      >
                        查看详情
                      </el-tag>
                    </div>
                  </div>
                  <div @click.prevent="" class="flex flex-sb pad-lr5">
                    <div></div>
                    <div>
                      <el-dropdown @command="handleCommand($event, job)" size="mini">
                        <i class="el-icon-more"></i>
                        <el-dropdown-menu slot="dropdown">
                          <template v-for="item in dropdownOptions(job)">
                            <el-dropdown-item :command="item">{{ item.label }}</el-dropdown-item>
                          </template>
                        </el-dropdown-menu>
                      </el-dropdown>
                    </div>
                  </div>
                </div>
              </el-col>
            </a>
          </template>
        </template>
      </template>
      <template v-else>
        <div class="empty-text">暂无数据</div>
      </template>
    </el-row>
    <div class="sctp-pad-tb10 sctp-flex sctp-flex-fe sctp-bg-white">
      <el-pagination
        :current-page="partTimeConfig.pageConfig.page"
        :page-size="partTimeConfig.pageConfig.limit"
        @size-change="partTimeConfig.pageConfig.handleSizeChange"
        @current-change="partTimeConfig.pageConfig.handleCurrentChange"
        :page-sizes="partTimeConfig.pageConfig.pageSizes"
        layout="prev, pager, next, total, sizes"
        :total="partTimeConfig.pageConfig.total"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import {collection} from "@/apis/index";
import area from "@/resources/js/area";

export default {
  name: "partTime",
  data() {
    return {
      partTimeConfig: {
        isLoadData: false,
        data: [],
        loadData: () => {
          let {page, limit} = this.partTimeConfig.pageConfig;
          this.partTimeConfig.isLoadData = true;
          collection.getPartTime({
            userId: this.user.userId,
            page, limit,
          }).then(res => {
            let {retdata = [], count = 0} = res;
            this.partTimeConfig.data = retdata;
            this.partTimeConfig.pageConfig.total = count;
          }).finally(() => {
            this.partTimeConfig.isLoadData = false;
          })
        },
        pageConfig: {
          page: 1,
          limit: 10,
          total: 0,
          pageSizes: [1, 2, 5, 10].map(item => item * 10),
          handleSizeChange: (limit) => {
            this.partTimeConfig.pageConfig.page = 1;
            this.partTimeConfig.pageConfig.limit = limit;
            this.partTimeConfig.loadData();
          },
          handleCurrentChange: (page) => {
            this.partTimeConfig.pageConfig.page = page;
            this.partTimeConfig.loadData();
          }
        },
      }
    }
  },
  methods: {
    handleCommand: (command, item) => {
      command.command(item);
    },
  },
  computed: {
    dropdownOptions() {
      return function (item) {
        let options = [];
        options = [
          {
            label: '取消收藏', command: () => {
              collection.addFavorite({
                userId: (this.user && this.user.userId) || null,
                targetId: item.jobid,
                targetType: 7
              }).then(res => {
                const {retdata} = res;
                if (retdata) {
                  this.$message.success('收藏成功')
                } else {
                  this.$message.success('取消收藏成功')
                }
                this.partTimeConfig.loadData();
              });
            }
          },
        ];
        return options;
      }
    },
    city() {
      return function (cityCode) {
        return area.parseCity(cityCode).name;
      }
    }
  },
  beforeMount() {
    this.partTimeConfig.loadData();
  }
}
</script>

<style scoped lang="scss">
.site-tag {
  overflow: hidden;
  position: relative;

  &:after {
    content: '源码网';
    display: block;
    position: absolute;
    font-size: 12px;
    background-color: rgba(255, 153, 0, .5);
    color: white;
    line-height: 20px;
    text-align: center;
    right: 0;
    top: 0;
    z-Index: 2;
    padding: 0 3em;
    transform-origin: left bottom;
    transform: translate(29.29%, -100%) rotate(45deg);
    text-indent: 0;
  }

}

.slider-card-title {
  padding-left: 15px;
  border-left: 3px solid $main-color;
}

.divider {
  height: 1px;
  border-bottom: #dddddd 1px dashed;
  margin-bottom: 8px;
  margin-top: 8px;
  width: 100%;
}

.rich-text {
  & ::v-deep p {
    display: inline-block;
  }
}
</style>
